<template lang="pug">
  FormModal(title="Edit Heading", method="PUT", :endpoint="`blocks/${block_id}`", :data="data", v-bind="$attrs", @success="onSuccess")
    InputGroup(name="title")
      TextInput(v-model="data.title", prepend-icon="block-heading", auto-focus)
    InputGroup(v-if="!collection_id", name="collection_ids", label="Collection(s) - optional", description="Click the chevron or your down arrow key to see list")
      MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="options/all/collections", option-label="label")
</template>

<script>
export default {
  props: {
    block_id: {
      type: Number,
      required: true
    },
    collection_id: {
      type: Number
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      title: ""
    }
  }),
  async created() {
    this.data.collection_id = this.collection_id

    let params = this.collection_id ? `?collection_id=${this.collection_id}` : ""
    let response = await this.$api.get(`blocks/${this.block_id}${params}`)

    this.data.title = response.data.data.title
    this.data.collection_ids = response.data.data.collection_ids
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Heading successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
